import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import AppContext from "../../../../context/AppContext";
import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import advancedFormat from "dayjs/plugin/advancedFormat";
import weekday from "dayjs/plugin/weekday";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Invoice, ProjectRole } from "../../../../API";
import useLoadingStatus from "../../../../hooks/useLoadingStatus";
import { createFilterFunction } from "../../functions/createFilterFunction";
import { ProjectPLProps } from "./ProjectPL";
import { useMantineReactTable } from "mantine-react-table";
import { Badge, Box, Group, SimpleGrid, Stack, Text } from "@mantine/core";
import { formatCurrency } from "../../functions/formatFunctions";

dayjs.extend(isBetween);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(utc);
dayjs.extend(timezone);

type Frequency = "Weekly" | "Monthly";

const formatDate = (date: Dayjs, unit: Frequency) => {
  const adjustedDate = date.add(5, "hours");
  return unit === "Weekly"
    ? `${adjustedDate.utc().format("YYYY")} ${adjustedDate.utc().week()}`
    : adjustedDate.utc().format("YYYY-MM");
};

function getDates(
  startDate: dayjs.Dayjs | string,
  endDate: dayjs.Dayjs | string,
  frequency: "Weekly" | "Monthly" | "At End"
): dayjs.Dayjs[] {
  // Ensure all dates are in UTC+0
  let current = dayjs.tz(startDate, "UTC");
  const end = dayjs.tz(endDate, "UTC");
  let dates: dayjs.Dayjs[] = [];

  if (frequency === "Weekly") {
    // Find the first Monday on or after the start date
    if (current.weekday() !== 1) {
      current = current.add(1, "week").weekday(1).tz("UTC");
    }

    // Collect all Mondays until the end date
    while (current.isBefore(end) || current.isSame(end, "day")) {
      dates.push(current.clone().tz("UTC")); // Ensure UTC+0
      current = current.add(1, "week").tz("UTC"); // Ensure UTC+0
    }
  } else if (frequency === "Monthly") {
    // Collect the last date of each month until the end date
    while (current.isBefore(end) || current.isSame(end, "day")) {
      dates.push(current.clone().endOf("month").tz("UTC")); // Ensure UTC+0
      current = current.add(1, "month").startOf("month").tz("UTC"); // Ensure UTC+0
    }
  } else if (frequency === "At End") {
    dates.push(end.clone().endOf("month").tz("UTC")); // Ensure UTC+0
  }

  return dates;
}

const CellDisplay = ({ dataObject }: any) => {
  const { name, forecastRevenue, forecastCost, actualRevenue, actualCost } =
    dataObject;
  const forecastProfit = forecastRevenue - forecastCost;
  const actualProfit = actualRevenue - actualCost;
  const profitVariance = actualProfit - forecastProfit;

  const revenueVariance = forecastRevenue - actualRevenue;

  const forecastMargin = forecastRevenue
    ? (forecastProfit * 100) / forecastRevenue
    : 0;
  const actualMargin = actualRevenue ? (actualProfit * 100) / actualRevenue : 0;

  const marginVariance = actualMargin - forecastMargin;

  return (
    <SimpleGrid cols={3}>
      <Stack align="center" spacing={"xs"}>
        {forecastRevenue > 0 && (
          <>
            <Text>{formatCurrency(forecastRevenue, "GBP", 0)}</Text>
            <Text>{formatCurrency(forecastCost, "GBP", 0)}</Text>
            <Badge color={forecastProfit > 0 ? "green" : "red"}>
              {formatCurrency(forecastProfit, "GBP", 0)}
            </Badge>
            <Badge color={forecastMargin > 0 ? "green" : "red"}>
              {forecastMargin.toFixed(0)}%
            </Badge>
          </>
        )}
      </Stack>
      <Stack align="center" spacing={"xs"}>
        {actualRevenue > 0 && (
          <>
            <Text>{formatCurrency(actualRevenue, "GBP", 0)}</Text>
            <Text>{formatCurrency(actualCost, "GBP", 0)}</Text>
            <Badge color={actualProfit > 0 ? "green" : "red"}>
              {formatCurrency(actualProfit, "GBP", 0)}
            </Badge>
            <Badge color={actualMargin > 0 ? "green" : "red"}>
              {actualMargin.toFixed(0)}%
            </Badge>
          </>
        )}
      </Stack>
      <Stack align="center" spacing={"xs"}>
        {actualRevenue > 0 && (
          <>
            <Text>{formatCurrency(revenueVariance, "GBP", 0)}</Text>
            <Text>{formatCurrency(forecastCost - actualCost, "GBP", 0)}</Text>
            <Badge color={profitVariance < 0 ? "red" : "green"}>
              {formatCurrency(profitVariance, "GBP", 0)}
            </Badge>
            <Badge
              color={
                marginVariance === 0
                  ? "gray"
                  : marginVariance > 0
                  ? "green"
                  : "red"
              }
            >
              {marginVariance.toFixed(0)}%
            </Badge>
          </>
        )}
      </Stack>
    </SimpleGrid>
  );
};

export function useProjectPL({
  dashboardFilterObjectList,
  updateDashboardFilter,
  globalFilteredData = [],
  comparisonGlobalFilteredData,
  comparisonFieldString,
  startDate,
  endDate,
  theme,
}: ProjectPLProps) {
  const [visualStartDate, setVisualStartDate] = useState<Dayjs>(() =>
    dayjs().subtract(6, "months").startOf("month")
  );
  const [visualEndDate, setVisualEndDate] = useState<Dayjs>(() =>
    dayjs().add(6, "months").endOf("month")
  );

  const [view, setView] = useState<Frequency>("Monthly");

  const visualDates = useMemo(
    () => getDates(visualStartDate, visualEndDate, view),
    [visualStartDate, visualEndDate, view]
  );

  const formatedVisualDates = useMemo(
    () => visualDates.map((date) => formatDate(dayjs(date), view)),
    [visualDates, view]
  );

  const {
    // dataObject: { projects = [] },
    currentProgrammes,
  } = useContext(AppContext);

  const loading = useLoadingStatus(["projects"]);

  const localFilterFunction = useMemo(
    () =>
      createFilterFunction(
        [dashboardFilterObjectList],
        "endDate",
        startDate,
        endDate
      ),
    [dashboardFilterObjectList, startDate, endDate]
  );

  const filteredProjects = useMemo(() => {
    return globalFilteredData
      .filter((project: any) =>
        currentProgrammes.includes(project.programmeProjectsId)
      )
      .filter(localFilterFunction);
  }, [globalFilteredData, localFilterFunction, currentProgrammes]);

  const tableValuesObject = useMemo(() => {
    return filteredProjects.map((project: any) => {
      const {
        startDate: projectStartDate,
        name,
        endDate: projectEndDate,
        projectRoles: { items: projectRoles = [] },
        statements: { items: statements = [] },
      } = project;

      // const toCheck =
      //   name ===
      //   "PO-8088000229 (John Bentley) VMware International Sep 24-Feb25";

      const returnObject: any = {
        name,
        headings: "Headings",
        total: {
          name: "Total",
          forecastRevenue: 0,
          forecastCost: 0,
          actualRevenue: 0,
          actualCost: 0,
        },
      };

      for (let date of formatedVisualDates) {
        returnObject[date] = {
          name: date,
          forecastRevenue: 0,
          forecastCost: 0,
          actualRevenue: 0,
          actualCost: 0,
        };
      }

      const invoices = statements.reduce((array: any, statement: any) => {
        const {
          invoices: { items: invoices },
        } = statement;
        array = array.concat(invoices);
        return array;
      }, []);

      const forecastValuesObject = projectRoles.reduce(
        (obj: any, projectRole: ProjectRole) => {
          //@ts-ignore
          const {
            billingFrequency = "Weekly",
            startDate,
            endDate, //@ts-ignore
            timesheets: { items: timesheets = [] },
          } = projectRole;

          for (let timesheet of timesheets) {
            const { endDate, unitAmount, unitCost } = timesheet;

            const formatedDate = formatDate(dayjs(endDate), view);
            if (!obj[formatedDate]) {
              obj[formatedDate] = {
                name: formatedDate,
                forecastRevenue: 0,
                forecastCost: 0,
                actualRevenue: 0,
                actualCost: 0,
              };
            }
            const cost = unitAmount * unitCost;

            obj[formatedDate].actualCost += cost;
            obj["total"].actualCost += cost;
          }

          if (billingFrequency && startDate && endDate) {
            //@ts-ignore
            const dates = getDates(startDate, endDate, billingFrequency);

            const totalValue =
              (projectRole.unitRate ?? 0) * (projectRole.plannedUnits ?? 0);

            const totalCost =
              (projectRole.unitCost ?? 0) * (projectRole.plannedUnits ?? 0);

            const unitValue = totalValue / dates.length;
            const unitCost = totalCost / dates.length;

            for (let date of dates) {
              const formatedDate = formatDate(dayjs(date), view);

              if (!obj[formatedDate]) {
                obj[formatedDate] = {
                  name: formatedDate,
                  forecastRevenue: 0,
                  forecastCost: 0,
                  actualRevenue: 0,
                  actualCost: 0,
                };
              }
              obj[formatedDate].forecastRevenue += unitValue;
              obj[formatedDate].forecastCost += unitCost;

              obj["total"].forecastRevenue += unitValue;
              obj["total"].forecastCost += unitCost;
            }
          }

          return obj;
        },
        returnObject
      );

      return invoices.reduce((obj: any, invoice: Invoice) => {
        if (invoice.invoiceDate) {
          const date = formatDate(dayjs(invoice.invoiceDate).utc(), view);
          // console.log(project.name);
          // if (
          //   project.name ===
          //   "PO-10425 Connect -  Leadership Support - Niall Anderson"
          // ) {
          //   console.log(
          //     date,
          //     invoice.invoiceDate,
          //     dayjs(invoice.invoiceDate).utc()
          //   );
          // }

          if (!obj[date])
            obj[date] = {
              name: date,
              forecastRevenue: 0,
              forecastCost: 0,
              actualRevenue: 0,
              actualCost: 0,
            };
          obj[date].actualRevenue += invoice.amount ?? 0;
          obj["total"].actualRevenue += invoice.amount ?? 0;
        }

        return obj;
      }, forecastValuesObject);
    });
  }, [filteredProjects, view, formatedVisualDates]);
  //tableValuesObject
  const totalsObject = useMemo(
    () =>
      ["total", ...formatedVisualDates].reduce((obj: any, key: any) => {
        obj[key] = {
          forecastRevenue: 0,
          forecastCost: 0,
          actualRevenue: 0,
          actualCost: 0,
        };

        for (let row of tableValuesObject) {
          obj[key].forecastRevenue += row[key].forecastRevenue;
          obj[key].forecastCost += row[key].forecastCost;
          obj[key].actualRevenue += row[key].actualRevenue;
          obj[key].actualCost += row[key].actualCost;
        }

        return obj;
      }, {}),
    [tableValuesObject, formatedVisualDates]
  );

  const columns = useMemo(() => {
    return [
      {
        accessorKey: "name",
        header: "Project",
        Cell: ({ cell, column, renderedCellValue, row, table }: any) => {
          return (
            <Group noWrap>
              <Box style={{ minWidth: 150 }}>
                <Text>{renderedCellValue}</Text>
              </Box>
              <Stack spacing={"xs"}>
                <Text>Revenue</Text>
                <Text>Costs</Text>
                <Text>Profit</Text>
                <Text>Margin</Text>
              </Stack>
            </Group>
          );
        },
        Footer: (
          <Group noWrap>
            <Box style={{ minWidth: 150 }}>
              <Text>Total</Text>
            </Box>
            <Stack spacing={"xs"}>
              <Text>Revenue</Text>
              <Text>Costs</Text>
              <Text>Profit</Text>
              <Text>Margin</Text>
            </Stack>
          </Group>
        ),
      },

      ...formatedVisualDates.map((date: any, index: number) => {
        return {
          accessorKey: index.toString(),
          // header: date,
          Footer: <CellDisplay dataObject={totalsObject[date]} />,
          // Footer: <div>Test</div>,
          minSize: 300,
          header: (
            <Stack align="center">
              <Text fw={700}>{date}</Text>
              <SimpleGrid cols={3} style={{ width: 300 }}>
                <Stack align="center">
                  <Text>Forecast</Text>
                </Stack>
                <Stack align="center">
                  <Text>Actual</Text>
                </Stack>
                <Stack align="center">
                  <Text>Variance</Text>
                </Stack>
              </SimpleGrid>
            </Stack>
          ),
          Cell: ({ cell, column, renderedCellValue, row, table }: any) => {
            return <CellDisplay dataObject={row.original[date]} />;
          },
        };
      }),
      {
        accessorKey: "total",
        // header: date,
        // footer: "Total",
        minSize: 300,
        header: (
          <Stack align="center">
            <Text fw={700}>Total</Text>
            <Group noWrap>
              <Box w={56} />
              <SimpleGrid cols={3} style={{ width: 300 }}>
                <Stack align="center">
                  <Text>Forecast</Text>
                </Stack>
                <Stack align="center">
                  <Text>Actual</Text>
                </Stack>
                <Stack align="center">
                  <Text>Variance</Text>
                </Stack>
              </SimpleGrid>
            </Group>
          </Stack>
        ),
        Footer: <CellDisplay dataObject={totalsObject["total"]} />,
        Cell: ({ cell, column, renderedCellValue, row, table }: any) => {
          return (
            <Group noWrap>
              <Stack spacing={"xs"} w={56}>
                <Text>Revenue</Text>
                <Text>Costs</Text>
                <Text>Profit</Text>
                <Text>Margin</Text>
              </Stack>
              <CellDisplay dataObject={row.original["total"]} />
            </Group>
          );
        },
      },
    ];
  }, [formatedVisualDates, totalsObject]);

  const table = useMantineReactTable({
    //@ts-ignore
    columns,
    data: tableValuesObject,
    enableColumnResizing: false,
    enableGrouping: true,
    enableStickyHeader: true,
    enableColumnDragging: false,
    enableStickyFooter: true,
    groupedColumnMode: false,
    enablePagination: false,
    enableSorting: false,
    enablePinning: true,
    enableColumnActions: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    mantineTableBodyCellProps: { sx: { textWrap: "wrap" } },
    // onSortingChange: (newSorting) => setSorting(newSorting),
    initialState: {
      density: "xs",
      //@ts-ignore
      expanded: false,
      grouping: [],
      //   pagination: { pageIndex: 0, pageSize: 100 },
      // sorting: [
      //   { id: `${periodObjectList[0].period}.utilisation`, desc: true },
      // ],
      columnPinning: { left: ["name"], right: ["total"] },
    },
    state: {
      //@ts-ignore
      //   expanded: false,
      // sorting,
      columnOrder: formatedVisualDates,
      columnVisibility: {
        //@ts-ignore
        // ...visibleFields[view],
        // engagementName: !(expanded === 0),
      },
    },
    mantineToolbarAlertBannerBadgeProps: { color: "blue", variant: "outline" },
    mantineTableContainerProps: { sx: {} },
    mantineTableProps: {
      style: { height: "100%" },
      withColumnBorders: true,
      highlightOnHover: false,
    },
    // mantineTableHeadCellProps: { width: "100%" },
    mantineTableHeadRowProps: {
      style: {
        boxShadow: `4px 0 ${theme.colorScheme === "dark" ? "8" : "4"}px ${
          theme.colors[theme.primaryColor][9]
        }`,
      },
    },
    mantinePaperProps: {
      style: {
        border: "none",
        boxShadow: "none",
        maxHeight: "100%",
        height: "100%",
        display: "grid",
        gridTemplateRows: "1fr 0px ",
      },
    },
  });

  return { view, loading, setView, tableValuesObject, table };
}
