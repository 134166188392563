import React from "react";
import useStyles from "./ProjectPL.styles";
import { useProjectPL } from "./useProjectPL";
import { LoadingOverlay, NativeSelect } from "@mantine/core";
import WidgetFrame from "../../WidgetFrame";
import { MantineReactTable } from "mantine-react-table";

export type ProjectPLProps = {
  dashboardFilterObjectList: any;
  updateDashboardFilter: any;
  globalFilteredData: any;
  comparisonGlobalFilteredData: any;
  comparisonFieldString: any;
  startDate: any;
  endDate: any;
  theme: any;
};

export function ProjectPL({
  dashboardFilterObjectList,
  updateDashboardFilter,
  globalFilteredData,
  comparisonGlobalFilteredData,
  comparisonFieldString,
  startDate,
  endDate,
}: ProjectPLProps) {
  const { classes, theme } = useStyles();
  const { view, loading, setView, table } = useProjectPL({
    dashboardFilterObjectList,
    updateDashboardFilter,
    globalFilteredData,
    comparisonGlobalFilteredData,
    comparisonFieldString,
    startDate,
    endDate,
    theme,
  });

  return (
    <WidgetFrame
      title={"Project Profit & Loss"}
      controls={
        <NativeSelect
          data={[
            { label: "Monthly", value: "Monthly" },
            { label: "Weekly", value: "Weekly" },
          ]}
          value={view} //@ts-ignore
          onChange={(e) => setView(e.target.value)}
          styles={{
            input: {
              backgroundColor:
                theme.colorScheme === "dark" ? theme.colors.dark[8] : undefined,
            },
          }}
        />
      }
    >
      <LoadingOverlay visible={loading} overlayOpacity={0} />
      {!loading && <MantineReactTable table={table} />}
    </WidgetFrame>
  );
}
