import { Group, Switch, createStyles, getStylesRef } from "@mantine/core";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import AppContext from "../../../../context/AppContext";
import { AccountTile } from "./AccountTile";
import WidgetFrame from "../../WidgetFrame";
import { Carousel, Embla } from "@mantine/carousel";
import useLoadingStatus from "../../../../hooks/useLoadingStatus";
import { createFilterFunction } from "../../functions/createFilterFunction";
import useFetchData from "../../../../hooks/useFetchData";
import { IconCalendar } from "@tabler/icons-react";

const useStyles = createStyles(() => ({
  controls: {
    ref: getStylesRef("controls"),
    transition: "opacity 150ms ease",
    opacity: 0,
  },

  root: {
    "&:hover": {
      [`& .${getStylesRef("controls")}`]: {
        opacity: 1,
      },
    },
  },
}));

export default function AccountSummary(props: any) {
  const {
    dashboardFilterObjectList,
    updateDashboardFilter,
    globalFilteredData = [],
    startDate,
    endDate,
  } = props;
  const { classes } = useStyles();
  const {
    dataObject: { accounts = [], risks = [] },
    currentTenant,
    currentAccount,
    setCurrentAccount,
    currentProgrammes = [],
  } = useContext(AppContext);
  const [embla, setEmbla] = useState<Embla | null>(null);
  const [visibleSlides, setVisibleSlides] = useState<number[]>([]);
  const [currentSlide, setCurrentSlide] = useState<number | null>(0);
  const [showRevenue, setShowRevenue] = useState<boolean>(false);

  useFetchData(["Accounts", "Projects", "Programmes", "Risks"], ["Live"]);

  const localFilterFunction = useMemo(
    () =>
      createFilterFunction(
        [dashboardFilterObjectList],
        "associatedDealCloseDate",
        startDate,
        endDate
      ),
    [dashboardFilterObjectList, startDate, endDate]
  );

  const loading = useLoadingStatus([
    "accounts",
    "projects",
    "programmes",
    "risks",
  ]);

  const filteredAccounts = useMemo(
    () =>
      currentAccount === "All"
        ? accounts.filter((account: any) => account.period === "Live")
        : accounts.filter((account: any) => {
            return account.id === currentAccount;
          }),
    [accounts, currentAccount]
  );

  useEffect(() => {
    if (embla) {
      const slidesInView = embla.slidesInView() ?? [0];
      setVisibleSlides([
        slidesInView[0] - 1,
        ...slidesInView, //@ts-ignore
        slidesInView.at(-1) + 1,
      ]);
    }
  }, [embla, filteredAccounts, currentSlide]);

  const filteredProjects = useMemo(() => {
    return globalFilteredData
      .filter(
        (project: any) =>
          project.period === "Live" &&
          currentProgrammes.includes(project.programmeProjectsId)
      )
      .filter(localFilterFunction);
  }, [globalFilteredData, localFilterFunction, currentProgrammes]);

  /*
  const comparisonGlobalFilteredData = data.filter((row: any) => {
    return (
      //currentProgramme === "All" ||
      permittedProgrammeList.includes(row[filterKey]) &&
      row.period === comparisonFieldString.periodString
    );
  });
  */

  const projectObject = useMemo(
    () =>
      filteredProjects.reduce((object: any, project: any) => {
        if (project.programme && project.programme.accountProgrammesId) {
          if (!object[project.programme.accountProgrammesId]) {
            object[project.programme.accountProgrammesId] = {
              total: 0,
              paid: 0,
              consumed: 0,
            };
          }
          object[project.programme.accountProgrammesId].total +=
            project.revenueSold;
          // object[project.programme.accountProgrammesId].consumed +=
          //   project.consumed;

          // object[project.programme.accountProgrammesId].paid +=
          //   project.revenueActual;

          object[project.programme.accountProgrammesId].consumed +=
            project?.statements?.items?.reduce((total: any, statement: any) => {
              total += statement?.invoices?.items.reduce(
                (total: any, invoice: any) => {
                  // if (!invoice.paid) {
                  total += invoice.amount;
                  // }
                  return total;
                },
                0
              );
              return total;
            }, 0);

          object[project.programme.accountProgrammesId].paid +=
            project?.statements?.items?.reduce((total: any, statement: any) => {
              total += statement?.invoices?.items.reduce(
                (total: any, invoice: any) => {
                  if (invoice.paid) {
                    total += invoice.amount;
                  }
                  return total;
                },
                0
              );
              return total;
            }, 0);
          return object;
        } else {
          return object;
        }
      }, {}),
    [filteredProjects]
  );

  return (
    <WidgetFrame
      title={"Accounts Summary"}
      icon={IconCalendar}
      controls={
        <Group>
          {/* <Switch
            label="Show Revenue"
            checked={showRevenue}
            onChange={(e) => setShowRevenue(e.target.checked)}
          /> */}
        </Group>
      }
    >
      <Carousel
        // mx="auto"
        align="start"
        slideGap="md"
        controlsOffset="xs"
        controlSize={21}
        px={"md"}
        // withIndicators
        classNames={classes}
        getEmblaApi={setEmbla}
        onSlideChange={setCurrentSlide}
        style={{ overflow: "hidden" }}
      >
        {filteredAccounts
          .sort((a: any, b: any) => {
            return a.name === b.name ? 0 : a.name > b.name ? -1 : 1;
          })
          .sort((a: any, b: any) => {
            return (
              (projectObject[a.id]?.total ?? 0) -
              (projectObject[b.id]?.total ?? 0)
            );
          })
          .reverse()
          .map((account: any, index: number) => {
            const accountProjects = filteredProjects.filter(
              (project: any) =>
                project.programme?.accountProgrammesId === account.id &&
                project.period === "Live"
            );

            return (
              // <Carousel.Slide size={400} key={account.id} p={5}>
              <Carousel.Slide
                size={showRevenue ? 400 : 200}
                key={account.id}
                p={5}
              >
                {visibleSlides.includes(index) ? (
                  <AccountTile
                    name={account.name}
                    setCurrentAccount={setCurrentAccount}
                    currentAccount={currentAccount}
                    id={account.id}
                    loading={loading}
                    tenant={currentTenant}
                    totalsObject={projectObject[account.id] ?? null}
                    completed={7200000}
                    total={100}
                    showRevenue={showRevenue}
                    stats={[
                      // {
                      //   value: programmes.filter(
                      //     (prog: any) =>
                      //       prog.accountProgrammesId === account.id &&
                      //       prog.period === "Live"
                      //   ).length,
                      //   label: "Programmes",
                      // },
                      {
                        value: accountProjects.length,
                        label: "Projects",
                      },
                      // {
                      //   value: risks.filter(
                      //     (risk: any) =>
                      //       risk.accountRisksId === account.id &&
                      //       risk.period === "Live"
                      //   ).length,
                      //   label: "Risks",
                      // },
                      {
                        value: risks.filter((risk: any) => {
                          return (
                            risk.accountAccessString === account.id &&
                            risk.status === "Open" &&
                            risk.period === "Live" &&
                            risk.impact * risk.probability >= 20
                          );
                        }).length,
                        label: "High Risks",
                      },
                    ]}
                  />
                ) : null}
              </Carousel.Slide>
            );
          })}
      </Carousel>
    </WidgetFrame>
  );
}
