export type Pillar =
  | "DEVELOP OUR PEOPLE"
  | "INSPIRE BELIEF"
  | "DELIVER OUTCOMES"
  | "DRIVE VALUE";

export type Behaviour =
  | "Empathetic"
  | "Grow Talent"
  | "Empower others"
  | "Align communication"
  | "Engage"
  | "Lead by example"
  | "Make it happen"
  | "Enable"
  | "Strive for results"
  | "Guide change"
  | "Be Data driven"
  | "Collaborate";

type StructureObject = {
  [P in Pillar]: {
    description: string;
    behaviours: {
      [B in Behaviour]?: {
        description: string;
        questions: { q: string; description: string }[];
      };
    };
  };
};

export type Question = {
  q: string;
  description: string;
  behaviour: Behaviour;
  pillar: Pillar;
};

export type QuestionList = Question[];

export const questionList: QuestionList = [
  {
    q: "q1",
    description:
      "We take our team under our wing. We have regular dialogue, create space to listen and ensure 1-1's and reviews happen.",
    behaviour: "Empathetic",
    pillar: "DEVELOP OUR PEOPLE",
  },
  {
    q: "q2",
    description:
      "We know our team at a personal and professional level and understand their capabilities, capacity, potential, personality and  behaviours.",
    behaviour: "Empathetic",
    pillar: "DEVELOP OUR PEOPLE",
  },
  {
    q: "q3",
    description:
      "We respect the need for consistent conduct & creating an inclusive environment to ensure everyone is treated fairly. We adapt our leadership styles to the situation.",
    behaviour: "Empathetic",
    pillar: "DEVELOP OUR PEOPLE",
  },
  {
    q: "q4",
    description:
      "We take accountability for our own growth and ensure are team do the same and that we all have actionable development plans in place.",
    behaviour: "Grow Talent",
    pillar: "DEVELOP OUR PEOPLE",
  },
  {
    q: "q5",
    description:
      "We drive excellence not perfection, and provide a supportive environment if people make mistakes.  We allow them to reflect, learn, refocus and grow, there is no blame. We adopt a coaching approach.",
    behaviour: "Grow Talent",
    pillar: "DEVELOP OUR PEOPLE",
  },
  {
    q: "q6",
    description:
      "We enable continuous learning, by identifying future career pathways and expose the team to opportunities for learning. We know who our successors are and work with them to realise it.",
    behaviour: "Grow Talent",
    pillar: "DEVELOP OUR PEOPLE",
  },
  {
    q: "q7",
    description:
      "We define SMART objectives and performance metrics for our teams that compliment our overall business objectives.",
    behaviour: "Empower others",
    pillar: "DEVELOP OUR PEOPLE",
  },
  {
    q: "q8",
    description:
      "We give clear guidance on job roles, responsibilities and provide autonomy for decision making. We hold people to account, and give clear direction and address any underperformance swiftly.",
    behaviour: "Empower others",
    pillar: "DEVELOP OUR PEOPLE",
  },
  {
    q: "q9",
    description:
      "We implement ways of recognising achievement at an individual and team level. We make informed decisions about reward balancing the budget and the market we work in.",
    behaviour: "Empower others",
    pillar: "DEVELOP OUR PEOPLE",
  },
  {
    q: "q10",
    description:
      "we ensure everyone understands the bigger picture and that we are unified as a team.  We align their personal and professional goals with the team and business objectives.",
    behaviour: "Align communication",
    pillar: "INSPIRE BELIEF",
  },
  {
    q: "q11",
    description:
      "We understand the context of others and formulate plans that encompass 2 way input.  We do this by encouraging collective feedback from the team and the wider business to create cross functional cohesion.",
    behaviour: "Align communication",
    pillar: "INSPIRE BELIEF",
  },
  {
    q: "q12",
    description:
      "We inspire confidence by giving time and space to others, checking that actions and decisions stay aligned with the agreed upon goals and strategies.",
    behaviour: "Align communication",
    pillar: "INSPIRE BELIEF",
  },
  {
    q: "q13",
    description:
      "We share relevant information openly and have a sense of appropriate timing in our updates, particularly in times of change. We stay optimistic during challenging times and remain positive.",
    behaviour: "Engage",
    pillar: "INSPIRE BELIEF",
  },
  {
    q: "q14",
    description:
      "We respect everyone's views and are open to opinion, input and feedback.  We truly value the teams contribution, and have their backs and act as the voice in the company.",
    behaviour: "Engage",
    pillar: "INSPIRE BELIEF",
  },
  {
    q: "q15",
    description:
      "We consider the context of our audience and communicate with clarity and consistency.  We inspire through stories, successes and learnings, and we give them the credit and have fun!",
    behaviour: "Engage",
    pillar: "INSPIRE BELIEF",
  },
  {
    q: "q16",
    description:
      "We embody the values, behaviours and work ethic we expect from others and bring them to life by talking about them and sharing and celebrating good examples.",
    behaviour: "Lead by example",
    pillar: "INSPIRE BELIEF",
  },
  {
    q: "q17",
    description:
      "We do what we say we will do and demonstrate trustworthiness by delivering on promises and actions.  We never betray their trust and admit when we are at fault.",
    behaviour: "Lead by example",
    pillar: "INSPIRE BELIEF",
  },
  {
    q: "q18",
    description:
      "We don't ask people to do something we wouldn't do and we treat them with respect.  We instil a customer centric ethos by engaging directly with customers and go the extra mile when necessary.",
    behaviour: "Lead by example",
    pillar: "INSPIRE BELIEF",
  },
  {
    q: "q19",
    description:
      "We create functional  plans demonstrating how our vision can be achieved. We analyse, adapt and take action on any underperformance of our objectives and celebrate successes.",
    behaviour: "Make it happen",
    pillar: "DELIVER OUTCOMES",
  },
  {
    q: "q20",
    description:
      "We ensure everyone understands their role in customer success, and listens and acts on feedback, CSAT results, and takes responsibility for helping improve upon that feedback.",
    behaviour: "Make it happen",
    pillar: "DELIVER OUTCOMES",
  },
  {
    q: "q21",
    description:
      "We instil a continuous improvement mentality.  We proactively take steps to ensure that operational processes,  systems and tools are optimised for efficiency & compliance and that customer journeys are frictionless.",
    behaviour: "Make it happen",
    pillar: "DELIVER OUTCOMES",
  },
  {
    q: "q22",
    description:
      "We foster collaborative relationships with peers and work together to review improvement opportunities within our functions, & support each other in delivering our plans successfully.",
    behaviour: "Enable",
    pillar: "DELIVER OUTCOMES",
  },
  {
    q: "q23",
    description:
      "We identify the skills and knowledge required to deliver the objectives and customer experience expected of us today and in the future.",
    behaviour: "Enable",
    pillar: "DELIVER OUTCOMES",
  },
  {
    q: "q24",
    description:
      "We ensure we have the right talent in the right place and optimise our resources. We focus on priorities and  know where we can have the greatest impact and are brave to say no to things.",
    behaviour: "Enable",
    pillar: "DELIVER OUTCOMES",
  },
  {
    q: "q25",
    description:
      "We produce defendable budgets and forecasts for our functional area and proactively benchmark, monitor and manage the commercial and financial drivers.",
    behaviour: "Strive for results",
    pillar: "DELIVER OUTCOMES",
  },
  {
    q: "q26",
    description:
      "We evaluate our resource levels and utilisation of our teams to proactively right size or reorganise showing respect for the work life balance and wellbeing of the team.",
    behaviour: "Strive for results",
    pillar: "DELIVER OUTCOMES",
  },
  {
    q: "q27",
    description:
      "We think things through and construct credible and persuasive business cases / proposals and gain buy-in by confidently positioning them to stakeholders.",
    behaviour: "Strive for results",
    pillar: "DELIVER OUTCOMES",
  },
  {
    q: "q28",
    description:
      "We adopt a a consultative selling approach and encourage teams to listen to customer needs, solve their problems in unique and efficient ways and educate the customer on new ideas.",
    behaviour: "Guide change",
    pillar: "DRIVE VALUE",
  },
  {
    q: "q29",
    description:
      "We evidence critical thinking, asking 'Why' and  challenging the status quo. We instil an optimisation mindset in our teams guided by the right outcomes for the customer and the business.",
    behaviour: "Guide change",
    pillar: "DRIVE VALUE",
  },
  {
    q: "q30",
    description:
      "We contribute towards shaping the future direction, by aligning our functional plans with innovations and how we can get there in the short / mid / long term.",
    behaviour: "Guide change",
    pillar: "DRIVE VALUE",
  },
  {
    q: "q31",
    description:
      "We proactively seek evidence, insights and analytics internally and externally to gain a holistic view and anticipate future trends and market changes. We use the data to guide us an make informed decisions and recommendations.",
    behaviour: "Be Data driven",
    pillar: "DRIVE VALUE",
  },
  {
    q: "q32",
    description:
      "We use customer insight and encourage customer knowledge sharing across functions to inform us. Solving these brings innovation as we evolve with the customer.",
    behaviour: "Be Data driven",
    pillar: "DRIVE VALUE",
  },
  {
    q: "q33",
    description:
      "We use data to evaluate the performance impact of our actions from a commercial and customer perspective and refine them based upon our learnings.",
    behaviour: "Be Data driven",
    pillar: "DRIVE VALUE",
  },
  {
    q: "q34",
    description:
      "We build our networks and foster a collaborative mindset by  interacting with our internal and external professional community to prompt growth and innovation.",
    behaviour: "Collaborate",
    pillar: "DRIVE VALUE",
  },
  {
    q: "q35",
    description:
      "We create a safe space for people to bring ideas, improvements and innovation about what we do and how we do it. We pull together the collective ideas and inspiration to inform decisions for future growth.",
    behaviour: "Collaborate",
    pillar: "DRIVE VALUE",
  },
  {
    q: "q36",
    description:
      "We recognise innovation is an iterative approach and encourage our team to work together to deliver a seamless customer experience.",
    behaviour: "Collaborate",
    pillar: "DRIVE VALUE",
  },
];

export const structureObject: StructureObject = {
  "DEVELOP OUR PEOPLE": {
    description:
      "We recruit, develop and retain diverse talent. Through building a supportive culture, we create a high performing team whose capabilities and skills evolve in line with the business strategy",
    behaviours: {
      Empathetic: {
        description:
          "We understand our people, and value the perspectives of others by being curious and actively listening to them.",
        questions: [
          {
            q: "q1",
            description:
              "We take our team under our wing. We have regular dialogue, create space to listen and ensure 1-1's and reviews happen.",
          },
          {
            q: "q2",
            description:
              "We know our team at a personal and professional level and understand their capabilities, capacity, potential, personality and  behaviours.",
          },
          {
            q: "q3",
            description:
              "We respect the need for consistent conduct & creating an inclusive environment to ensure everyone is treated fairly. We adapt our leadership styles to the situation.",
          },
        ],
      },
      "Grow Talent": {
        description:
          "We develop, coach and mentor the performance and potential of the team, ourselves and others.",
        questions: [
          {
            q: "q4",
            description:
              "We take accountability for our own growth and ensure are team do the same and that we all have actionable development plans in place.",
          },
          {
            q: "q5",
            description:
              "We drive excellence not perfection, and provide a supportive environment if people make mistakes.  We allow them to reflect, learn, refocus and grow, there is no blame. We adopt a coaching approach.",
          },
          {
            q: "q6",
            description:
              "We enable continuous learning, by identifying future career pathways and expose the team to opportunities for learning. We know who our successors are and work with them to realise it. ",
          },
        ],
      },
      "Empower others": {
        description:
          "We provide support, clear expectations and autonomy, and give constructive feedback. We help people to work together.",
        questions: [
          {
            q: "q7",
            description:
              "We define SMART objectives and performance metrics for our teams that compliment our overall business objectives.",
          },
          {
            q: "q8",
            description:
              "We give clear guidance on job roles, responsibilities and provide autonomy for decision making. We hold people to account, and give clear direction and address any underperformance swiftly.",
          },
          {
            q: "q9",
            description:
              "We implement ways of recognising achievement at an individual and team level. We make informed decisions about reward balancing the budget and the market we work in.",
          },
        ],
      },
    },
  },
  "INSPIRE BELIEF": {
    description:
      "We instil confidence in and earn the trust of the team, peers, stakeholders by the actions we take and openly communicating with passion & conviction.",
    behaviours: {
      "Align communication": {
        description:
          "We provide the team with a clear direction and build confidence in our strategy and us, by aligning everyone to the vision, purpose and values.",
        questions: [
          {
            q: "q10",
            description:
              "we ensure everyone understands the bigger picture and that we are unified as a team.  We align their personal and professional goals with the team and business objectives.",
          },
          {
            q: "q11",
            description:
              "We understand the context of others and formulate plans that encompass 2 way input.  We do this by encouraging collective feedback from the team and the wider business to create cross functional cohesion.",
          },
          {
            q: "q12",
            description:
              "We inspire confidence by giving time and space to others, checking that actions and decisions stay aligned with the agreed upon goals and strategies.",
          },
        ],
      },
      Engage: {
        description:
          "We facilitate understanding and reinforce 'One team, One Voice' by ensuring everyone is on the same page through consistent and transparent communication.",
        questions: [
          {
            q: "q13",
            description:
              "We share relevant information openly and have a sense of appropriate timing in our updates, particularly in times of change. We stay optimistic during challenging times and remain positive.",
          },
          {
            q: "q14",
            description:
              "We respect everyone's views and are open to opinion, input and feedback.  We truly value the teams contribution, and have their backs and act as the voice in the company. ",
          },
          {
            q: "q15",
            description:
              "We consider the context of our audience and communicate with clarity and consistency.  We inspire through stories, successes and learnings, and we give them the credit and have fun!",
          },
        ],
      },
      "Lead by example": {
        description:
          "We demonstrate consistently how our values show up day to day and act with integrity and authenticity.",
        questions: [
          {
            q: "q16",
            description:
              "We embody the values, behaviours and work ethic we expect from others and bring them to life by talking about them and sharing and celebrating good examples.",
          },
          {
            q: "q17",
            description:
              "We do what we say we will do and demonstrate trustworthiness by delivering on promises and actions.  We never betray their trust and admit when we are at fault.",
          },
          {
            q: "q18",
            description:
              "We don’t ask people to do something we wouldn’t do and we treat them with respect.  We instil a customer centric ethos by engaging directly with customers and go the extra mile when necessary.",
          },
        ],
      },
    },
  },
  "DELIVER OUTCOMES": {
    description:
      "We play our part in running a profitable, sustainable and successful business by focusing our resources on the right outcomes for our customers and our stakeholders.",
    behaviours: {
      "Make it happen": {
        description:
          "We focus our teams on delivering business objectives and plans that result in quality customer outcomes whilst being profitable for Connect.",
        questions: [
          {
            q: "q19",
            description:
              "We create functional  plans demonstrating how our vision can be achieved. We analyse, adapt and take action on any underperformance of our objectives and celebrate successes. ",
          },
          {
            q: "q20",
            description:
              "We ensure everyone understands their role in customer success, and listens and acts on feedback, CSAT results, and takes responsibility for helping improve upon that feedback.",
          },
          {
            q: "q21",
            description:
              "We instil a continuous improvement mentality.  We proactively take steps to ensure that operational processes,  systems and tools are optimised for efficiency & compliance and that customer journeys are frictionless.",
          },
        ],
      },
      Enable: {
        description:
          "We enable our teams to deliver brilliantly by operating decisively in the execution of our plans through effective and efficient resource management.",
        questions: [
          {
            q: "q22",
            description:
              "We foster collaborative relationships with peers and work together to review improvement opportunities within our functions, & support each other in delivering our plans successfully.",
          },
          {
            q: "q23",
            description:
              "We identify the skills and knowledge required to deliver the objectives and customer experience expected of us today and in the future. ",
          },
          {
            q: "q24",
            description:
              "We ensure we have the right talent in the right place and optimise our resources. We focus on priorities and  know where we can have the greatest impact and are brave to say no to things.",
          },
        ],
      },
      "Strive for results": {
        description:
          "We have a P&L commercial mindset and own the numbers.  We contribute to and balance the business profitability by influencing the measures that matter.",
        questions: [
          {
            q: "q25",
            description:
              "We produce defendable budgets and forecasts for our functional area and proactively benchmark, monitor and manage the commercial and financial drivers.",
          },
          {
            q: "q26",
            description:
              "We evaluate our resource levels and utilisation of our teams to proactively right size or reorganise showing respect for the work life balance and wellbeing of the team.",
          },
          {
            q: "q27",
            description:
              "We think things through and construct credible and persuasive business cases / proposals and gain buy-in by confidently positioning them to stakeholders.",
          },
        ],
      },
    },
  },
  "DRIVE VALUE": {
    description:
      "We differentiate ourselves in the market by innovating and finding ways to create customer value by improving our customer experience, technology and our service levels.",
    behaviours: {
      "Guide change": {
        description:
          "We are brave and set goals to improve our proposition and service so that we increase adoption, retention, and growth of our customers.",
        questions: [
          {
            q: "q28",
            description:
              "We adopt a a consultative selling approach and encourage teams to listen to customer needs, solve their problems in unique and efficient ways and educate the customer on new ideas.",
          },
          {
            q: "q29",
            description:
              "We evidence critical thinking, asking 'Why' and  challenging the status quo. We instil an optimisation mindset in our teams guided by the right outcomes for the customer and the business.",
          },
          {
            q: "q30",
            description:
              "We contribute towards shaping the future direction, by aligning our functional plans with innovations and how we can get there in the short / mid / long term.",
          },
        ],
      },
      "Be Data driven": {
        description:
          "We use data from various sources to think through options to come to a better overall solution. We reflect and learn from failure and successes.",
        questions: [
          {
            q: "q31",
            description:
              "We proactively seek evidence, insights and analytics internally and externally to gain a holistic view and anticipate future trends and market changes. We use the data to guide us an make informed decisions and recommendations.",
          },
          {
            q: "q32",
            description:
              "We use customer insight and encourage customer knowledge sharing across functions to inform us. Solving these brings innovation as we evolve with the customer.",
          },
          {
            q: "q33",
            description:
              "We use data to evaluate the performance impact of our actions from a commercial and customer perspective and refine them based upon our learnings.",
          },
        ],
      },
      Collaborate: {
        description:
          "We work together to leverage the skills of our global community to solve problems, find solutions and streamline processes.",
        questions: [
          {
            q: "q34",
            description:
              "We build our networks and foster a collaborative mindset by  interacting with our internal and external professional community to prompt growth and innovation.",
          },
          {
            q: "q35",
            description:
              "We create a safe space for people to bring ideas, improvements and innovation about what we do and how we do it. We pull together the collective ideas and inspiration to inform decisions for future growth.",
          },
          {
            q: "q36",
            description:
              "We recognise innovation is an iterative approach and encourage our team to work together to deliver a seamless customer experience.",
          },
        ],
      },
    },
  },
};
