import common from "./commonSchema";
import { ISchema } from "../SchemaTypes";
import {
  formatDateString,
  formatCurrency,
  formatPercentage,
} from "../../components/common/functions/formatFunctions";
// import { countryNames } from "../data";
import {
  listDeliveryManagerProjects,
  listProjectManagerProjects,
} from "../../graphql/queries";
import {
  createDeliveryManagerProjects,
  createProjectManagerProjects,
  deleteDeliveryManagerProjects,
  deleteProjectManagerProjects,
  updateDeliveryManagerProjects,
  updateProjectManagerProjects,
} from "../../graphql/mutations";
import { Badge, Progress, useMantineTheme } from "@mantine/core";
import {
  calculatePercentageOfDurationPassed,
  networkDays,
} from "../../components/common/functions/dateFunctions";
import dayjs from "dayjs";
// import { ProjectRole, Timesheet } from "../../API";

const calculateRevenueActual = (value: any) =>
  value?.statements?.items?.reduce((total: any, statement: any) => {
    total += statement?.invoices?.items.reduce((total: any, invoice: any) => {
      total += invoice.paid ? invoice.amount : 0;
      return total;
    }, 0);
    return total;
  }, 0);

const calculateFinanceObject = (value: any) =>
  value?.statements?.items?.reduce(
    (object: any, statement: any) => {
      statement?.invoices?.items.forEach((invoice: any) => {
        object.paid += invoice.paid ? invoice.amount : 0;
        object.invoiced += invoice.amount;
      });
      return object;
    },
    { paid: 0, invoiced: 0, value: value.revenueSold }
  );

const calculateCostsPlanned = (value: any) =>
  value?.projectRoles?.items?.reduce((total: any, projectRole: any) => {
    total += projectRole.plannedUnits * projectRole.unitRate;
    return total;
  }, 0);

const calculateCostsActual = (value: any) => {
  return (
    value?.projectRoles?.items?.reduce((total: any, projectRole: any) => {
      total += projectRole.actualUnits * projectRole.unitRate;
      return total;
    }, 0) ?? 0
  );
};
function DurationLine({ width, children, duration }: any) {
  const theme = useMantineTheme();
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          left: width * duration,
          width: 1,
          height: "100%",
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.gray[2]
              : theme.colors.gray[9],
          zIndex: 1,
        }}
      ></div>
      {children}
    </div>
  );
}

const percentagePlannedUnitsAccessFunction = (value: any) => {
  const totalsObject = value?.projectRoles?.items?.reduce(
    (object: any, projectRole: any) => {
      object.planned +=
        (projectRole.plannedUnits ?? 0) * (projectRole.unit === "Days" ? 8 : 1);
      if (projectRole?.timesheets?.items?.length) {
        projectRole?.timesheets?.items.forEach((timesheet: any) => {
          object.actual +=
            (timesheet.unitAmount ?? 0) * (timesheet.unit === "Days" ? 8 : 1);
        });
      } else {
        object.actual += projectRole.actualUnits;
      }
      return object;
    },
    { planned: 0, actual: 0 }
  );
  if (!totalsObject)
    return {
      value: 0,
      duration: calculatePercentageOfDurationPassed({
        startDate: value.startDate,
        endDate: value.endDate,
      }),
    };
  const { planned, actual } = totalsObject;
  return {
    value: planned === 0 ? 0 : actual / planned,
    duration: calculatePercentageOfDurationPassed({
      startDate: value.startDate,
      endDate: value.endDate,
    }),
  };
};

const marginActualAccessorFunction = (value: any) => {
  const revActual = calculateRevenueActual(value);
  const costActal = calculateCostsActual(value);
  if (!revActual) return 0;
  const returnValue = (revActual - costActal) / revActual;
  return returnValue;
};

const marginVarianceAccessorFunction = (value: any) => {
  const revActual = calculateRevenueActual(value);
  const costsActual = calculateCostsActual(value);

  return (
    (revActual - costsActual) / revActual -
    (value.revenueSold - calculateCostsPlanned(value)) / value.revenueSold
  );
};

const percentageOfTasksCompletedVal = (value: any) => {
  const { items } = value.tasks;
  const total = items.length;
  const val =
    total === 0 ? 0 : items.filter((item: any) => item.complete).length / total;
  return val;
};

const projectsSchema: ISchema = {
  region: {
    title: "Region",
    field: "region",
    accessorFunction: (value: any) => value?.region?.name,
    // formatFunction: (value: any) => value?.name,
    formOptions: { required: false },
    excludeFromForm: true,
    type: "belongsTo",
    excludeFromChartBuilder: true,
  },
  regionName: {
    title: "Country Name",
    field: "regionName",
    accessorFunction: (value: any) => value?.region?.name,
    // formatFunction: (value: any) => value?.name,
    formOptions: { required: false },
    excludeFromForm: true,
    type: "belongsTo",
  },
  subRegionName: {
    title: "Subregion Name",
    field: "subRegionName",
    accessorFunction: (value: any) => value?.region?.subregion,
    // formatFunction: (value: any) => value?.name,
    formOptions: { required: false },
    excludeFromForm: true,
    type: "belongsTo",
  },
  regionProjectsId: {
    title: "Region",
    field: "regionProjectsId",
    excludeFromChartBuilder: true,
    formOptions: {
      required: false,
      options: {
        // list: [""].concat(countryNames),
        dataSource: "regions",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource
            ?.sort((a: any, b: any) => {
              return a.name === b.name ? 0 : a.name > b.name ? 1 : -1;
            })
            .map((row: any) => {
              return { value: row.id, label: row.name };
            }) ?? [],
      },
      order: 2,
    },
    type: "select",
  },

  verticalName: {
    title: "Vertical Name",
    field: "verticalName",
    accessorFunction: (value: any) => value?.programme?.vertical?.name,
    formOptions: { required: false },
    excludeFromForm: true,
    type: "belongsTo",
  },

  accountName: {
    title: "Account Name",
    field: "accountName",
    accessorFunction: (value: any) => value?.programme?.account?.name,
    formOptions: { required: false },
    excludeFromForm: true,
    type: "belongsTo",
  },

  programmeName: {
    title: "Programme Name",
    field: "programmeName",
    accessorFunction: (value: any) => value.programme?.name,
    // formatFunction: (value: any) => value,
    formOptions: { required: false },
    excludeFromForm: true,
    type: "belongsTo",
  },

  account: {
    title: "Account",
    field: "account",
    accessorFunction: (value: any) => value.account?.name,
    // formatFunction: (value: any) => value,
    formOptions: { required: false },
    excludeFromForm: true,
    excludeFromChartBuilder: true,
    type: "belongsTo",
  },

  accountProjectsId: {
    title: "Account",
    field: "accountProjectsId",
    excludeFromChartBuilder: true,
    formOptions: {
      required: false,
      options: {
        dataSource: "accounts",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: row.name };
          }) ?? [],
      },
      order: 1,
    },
    type: "select",
  },

  vertical: {
    title: "Vertical",
    field: "vertical",
    accessorFunction: (value: any) => value.vertical?.name,
    // formatFunction: (value: any) => value,
    formOptions: { required: false },
    excludeFromForm: true,
    excludeFromChartBuilder: true,
    type: "belongsTo",
  },

  verticalProjectsId: {
    title: "Vertical",
    field: "verticalProjectsId",
    excludeFromChartBuilder: true,
    formOptions: {
      required: false,
      options: {
        dataSource: "verticals",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: row.name };
          }) ?? [],
      },
      order: 1,
    },
    type: "select",
  },

  programme: {
    title: "Programme",
    field: "programme",
    accessorFunction: (value: any) => value.programme?.name,
    // formatFunction: (value: any) => value,
    formOptions: { required: false },
    excludeFromForm: true,
    excludeFromChartBuilder: true,
    type: "belongsTo",
  },

  programmeProjectsId: {
    title: "Programme",
    field: "programmeProjectsId",
    excludeFromChartBuilder: true,
    formOptions: {
      required: false,
      options: {
        dataSource: "programmes",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource
            ?.filter((record: any) => record.period === "Live")
            .map((row: any) => {
              return { value: row.id, label: row.name };
            }) ?? [],
      },
      order: 1,
    },
    type: "select",
  },

  name: {
    title: "Name",
    field: "name",
    type: "text",
    identifier: true,
    formOptions: {
      required: true,
    },
    equivalentFields: { invoices: "projectName", projectRoles: "projectName" },
  },
  tcv: {
    title: "Total Contract Value (TCV)",
    field: "tcv",
    type: "number",
    formOptions: {
      required: false,
    },
    excludeFromForm: true,
    excludeFromChartBuilder: true,
  },
  margin: {
    title: "Margin",
    field: "margin",
    type: "number",
    formOptions: {
      required: false,
    },
    excludeFromForm: true,
    excludeFromChartBuilder: true,
  },
  workstream: {
    title: "Workstream",
    field: "workstream",
    type: "text",
    formOptions: {
      required: false,
    },
    excludeFromForm: true,
    excludeFromChartBuilder: true,
  },
  currency: {
    title: "Currency",
    field: "currency",
    type: "text",
    formOptions: {
      required: false,
    },
  },
  budget: {
    title: "Budget",
    field: "budget",
    type: "number",
    formOptions: {
      required: false,
    },
    excludeFromForm: true,
    excludeFromChartBuilder: true,
  },
  spend: {
    title: "Spend",
    field: "spend",
    type: "number",
    formOptions: {
      required: false,
    },
    excludeFromForm: true,
    excludeFromChartBuilder: true,
  },

  revenueSold: {
    title: "Revenue Sold",
    field: "revenueSold",
    type: "number",
    formatFunction: (value: any) => formatCurrency(value, "GBP", 0),
    formOptions: {
      required: false,
    },
  },
  costSold: {
    title: "Cost Sold",
    field: "costSold",
    type: "number",
    formatFunction: (value: any) => formatCurrency(value, "GBP", 0),
    formOptions: {
      required: false,
    },
  },
  marginSold: {
    title: "Margin Sold",
    field: "marginSold",
    type: "number",
    accessorFunction: (value: any) => {
      return (
        (value.revenueSold - calculateCostsPlanned(value)) / value.revenueSold
      );
      // return (value.revenueSold - value.costSold) / value.revenueSold;
    },
    formatFunction: (value: any) => {
      if (isNaN(value)) return "-";
      if (!isFinite(value)) {
        return value > 0 ? "∞" : "-∞";
      }
      return formatPercentage(value);
    },
    excludeFromForm: true,
    formOptions: {
      required: false,
    },
  },
  revenueActual: {
    title: "Revenue Actual",
    field: "revenueActual",
    type: "number",
    formatFunction: (value: any) => formatCurrency(value, "GBP", 0),
    formOptions: {
      required: false,
    },
  },
  revenueActualInvoices: {
    title: "Revenue Claimed",
    field: "revenueActualInvoices",
    type: "number",
    formatFunction: (value: any) => formatCurrency(value, "GBP", 0),
    accessorFunction: (value: any) => calculateFinanceObject(value).paid, //calculateRevenueActual,
    excludeFromForm: true,
    formOptions: {
      required: false,
    },
  },

  revenueUnclaimed: {
    title: "Revenue Unclaimed (From Invoices)",
    field: "revenueUnclaimed",
    type: "number",
    formatFunction: (value: any) => formatCurrency(value, "GBP", 0),
    accessorFunction: (value: any) =>
      value.revenueSold - calculateFinanceObject(value).paid, //calculateRevenueActual,
    excludeFromForm: true,
    formOptions: {
      required: false,
    },
  },

  financeSummary: {
    title: "Revenue Summary",
    field: "financeSummary",
    type: "number",
    formatFunction: (value: any, width = 300) => {
      return (
        <DurationLine width={width} duration={value.duration}>
          <>
            {value.paid + value.invoiced === 0 ? (
              <Progress
                radius="xl"
                size={"1.25em"}
                miw={width}
                maw={width}
                value={0}
              />
            ) : (
              <Progress
                radius="xl"
                size={"1.25em"}
                miw={width}
                maw={width}
                sections={[
                  {
                    value: (value.paid * 100) / value.value,
                    color: "green",
                    label: formatCurrency(value.paid, "GBP", 0),
                    tooltip: "Revenue Claimed",
                  },
                  {
                    value: ((value.invoiced - value.paid) * 100) / value.value,
                    color: "orange",
                    label: formatCurrency(
                      value.invoiced - value.paid,
                      "GBP",
                      0
                    ),
                    tooltip: "Revenue Recognised",
                  },
                  {
                    value: ((value.value - value.invoiced) * 100) / value.value,
                    color: "red",
                    label: formatCurrency(
                      value.value - value.invoiced,
                      "GBP",
                      0
                    ),
                    tooltip: "Revenue Forecast",
                  },
                ]}
              />
            )}
          </>
        </DurationLine>
      );
    },
    accessorFunction: (value: any) => ({
      ...calculateFinanceObject(value),
      duration: calculatePercentageOfDurationPassed({
        startDate: value.startDate,
        endDate: value.endDate,
      }),
    }),
    sortingFunction: (a: any, b: any) => {
      return a.original.revenueSold - b.original.revenueSold;
    },
    enableSorting: true,
    excludeFromForm: true,
    formOptions: {
      required: false,
    },
  },

  duration: {
    title: "Duration",
    field: "duration",
    type: "number",
    formOptions: {
      required: false,
    },
    excludeFromForm: true,
    accessorFunction: (value: any) =>
      `${networkDays({
        startDate: value.startDate,
        endDate: value.endDate,
      })} days`,
  },

  costsPlannedRoles: {
    title: "Planned Cost (From Roles)",
    field: "costsPlannedRoles",
    type: "number",
    formatFunction: (value: any) => formatCurrency(value, "GBP", 0),
    accessorFunction: calculateCostsPlanned,
    excludeFromForm: true,
    formOptions: {
      required: false,
    },
  },
  costActual: {
    title: "Cost Actual (From Roles)",
    field: "costActual",
    type: "number",
    formatFunction: (value: any) => formatCurrency(value, "GBP", 0),
    accessorFunction: calculateCostsActual,
    formOptions: {
      required: false,
    },
  },
  consumed: {
    title: "Consumed",
    field: "consumed",
    type: "number",
    formOptions: {
      required: false,
    },
  },
  profit: {
    title: "Profit",
    field: "profit",
    type: "number",
    accessorFunction: (row: any) => row.revenueActual - row.costActual,
    formatFunction: (value: any) => formatCurrency(value, "GBP", 0),
    excludeFromForm: true,
    formOptions: {
      required: false,
    },
  },

  profitCalculated: {
    title: "Actual Profit (From Invoices & Roles)",
    field: "profitCalculated",
    type: "number",
    accessorFunction: (row: any) =>
      calculateFinanceObject(row).paid - calculateCostsActual(row),
    formatFunction: (value: any) => formatCurrency(value, "GBP", 0),
    excludeFromForm: true,
    formOptions: {
      required: false,
    },
  },

  forecastProfitCalculated: {
    title: "Forecast Profit (From Roles)",
    field: "forecastProfitCalculated",
    type: "number",
    accessorFunction: (row: any) =>
      row.revenueSold - calculateCostsPlanned(row),
    formatFunction: (value: any) => formatCurrency(value, "GBP", 0),
    excludeFromForm: true,
    formOptions: {
      required: false,
    },
  },

  marginActual: {
    title: "Margin Actual",
    field: "marginActual",
    type: "number",
    accessorFunction: marginActualAccessorFunction,
    // accessorFunction: (value: any) => {
    //   return (value.revenueActual - value.costActual) / value.revenueActual;
    // },
    formatFunction: (value: any) => {
      if (isNaN(value)) return "-";
      if (!isFinite(value)) {
        return value > 0 ? "∞" : "-∞";
      }
      return formatPercentage(value);
    },
    sortingFunction: (a: any, b: any) => {
      const marginA = marginActualAccessorFunction(a.original);
      const marginB = marginActualAccessorFunction(b.original);

      const finalA = isNaN(marginA) || !isFinite(marginA) ? 0 : marginA;
      const finalB = isNaN(marginB) || !isFinite(marginB) ? 0 : marginB;

      return finalA - finalB;
    },
    excludeFromForm: true,
    formOptions: {
      required: false,
    },
  },

  percentagePlannedUnitsUsed: {
    title: "Percentage of Planned Hours Used",
    field: "percentagePlannedUnitsUsed",
    type: "number",
    accessorFunction: percentagePlannedUnitsAccessFunction,
    formatFunction: (value: any) => {
      const rounded = Math.round(value.value * 100);
      return (
        <DurationLine width={200} duration={value.duration}>
          <Progress
            value={rounded}
            label={`${rounded}%`}
            color={rounded > 100 ? "red" : undefined}
            // size="xl"
            radius="xl"
            size={"1.25em"}
            miw={200}
            maw={200}
          />
        </DurationLine>
      );
    },
    sortingFunction: (a: any, b: any) => {
      const aObject =
        percentagePlannedUnitsAccessFunction(a.original)?.value ?? 0;
      const bObject =
        percentagePlannedUnitsAccessFunction(b.original)?.value ?? 0;
      return aObject - bObject;
    },
    excludeFromForm: true,
    formOptions: {
      required: false,
    },
  },

  percentagePlannedUnitsUsedNumber: {
    title: "Percentage of Planned Hours Used Number",
    field: "percentagePlannedUnitsUsedNumber",
    type: "number",
    accessorFunction: (value: any) => {
      const totalsObject = value?.projectRoles?.items?.reduce(
        (object: any, projectRole: any) => {
          object.planned += projectRole.plannedUnits;
          object.actual += projectRole.actualUnits;
          return object;
        },
        { planned: 0, actual: 0 }
      );
      const { planned, actual } = totalsObject;
      return planned === 0 ? 0 : actual / planned;
    },

    excludeFromForm: true,
    formOptions: {
      required: false,
    },
  },

  timingStatus: {
    title: "Timing Status",
    field: "timingStatus",
    accessorFunction: (value: any) => {
      if (!value.startDate || !value.endDate) return "Missing Dates";
      if (dayjs(value.startDate).isAfter(dayjs())) return "Upcoming";
      if (dayjs(value.endDate).isBefore(dayjs())) return "Finished";
      return "In Progress";
    },
    excludeFromForm: true,
    formOptions: {
      required: false,
    },
  },

  marginVariance: {
    title: "Margin Variance",
    field: "marginVariance",
    type: "number",
    accessorFunction: marginVarianceAccessorFunction,
    formatFunction: (value: any) => {
      return isNaN(value) ? (
        "-"
      ) : (
        <Badge
          color={value === 0 ? "gray" : value > 0 ? "green" : "red"}
          size="lg"
        >
          {formatPercentage(value)}
        </Badge>
      );
    },
    sortingFunction: (a: any, b: any) => {
      const aVar = marginVarianceAccessorFunction(a.original);
      const bVar = marginVarianceAccessorFunction(b.original);

      const aFinal = isNaN(aVar) ? -1 : aVar;
      const bFinal = isNaN(bVar) ? -1 : bVar;

      return aFinal - bFinal;
    },
    excludeFromForm: true,
    formOptions: {
      required: false,
    },
  },

  status: {
    title: "Status",
    field: "status",
    type: "text",
    formOptions: {
      required: false,
    },
  },

  startDate: {
    title: "Start Date",
    field: "startDate",
    type: "date",
    formatFunction: formatDateString,
    sortingFunction: (a: any, b: any) => {
      const aFinal = a.original.startDate ?? "";
      const bFinal = b.original.startDate ?? "";
      return aFinal === bFinal ? 0 : aFinal > bFinal ? 1 : -1;
    },
    formOptions: {
      required: false,
    },
  },
  endDate: {
    title: "End Date",
    field: "endDate",
    type: "date",
    sortingFunction: (a: any, b: any) => {
      const aFinal = a.original.endDate ?? "";
      const bFinal = b.original.endDate ?? "";
      return aFinal === bFinal ? 0 : aFinal > bFinal ? 1 : -1;
    },
    formatFunction: formatDateString,
    formOptions: {
      required: false,
    },
  },

  associatedDealCloseDate: {
    title: "Associated Deal Close Date",
    field: "associatedDealCloseDate",
    type: "date",
    formatFunction: formatDateString,
    accessorFunction: (row: any) => {
      const statements = row?.statements?.items;
      return statements[0] ? statements[0].pipeline?.closeDate : null;
    },
    excludeFromForm: true,
    formOptions: {
      required: false,
    },
  },

  active: {
    title: "Active",
    field: "active",
    type: "text",
    accessorFunction: (row: any) => {
      const now = dayjs();
      return dayjs(row.startDate).isBefore(now) &&
        dayjs(row.endDate).isAfter(now)
        ? "Active"
        : "Inactive";
    },
    formOptions: {
      required: false,
    },
    excludeFromForm: true,
  },

  numberOfStatementsOfWork: {
    title: "Number of Statements Of Work",
    field: "numberOfStatementsOfWork",
    accessorFunction: (value: any) => {
      return value.statements.items.length;
    },
    type: "belongsTo",
    excludeFromForm: true,
    formOptions: {
      required: false,
    },
  },

  numberOfTasks: {
    title: "Number of Tasks",
    field: "numberOfTasks",
    accessorFunction: (value: any) => {
      return value.tasks.items.length;
    },
    type: "belongsTo",
    excludeFromForm: true,
    formOptions: {
      required: false,
    },
  },

  percentageOfTasksCompleted: {
    title: "Percentage of Tasks Completed",
    field: "percentageOfTasksCompleted",
    accessorFunction: (value: any) => {
      const val = percentageOfTasksCompletedVal(value);
      return {
        val,
        duration: calculatePercentageOfDurationPassed({
          startDate: value.startDate,
          endDate: value.endDate,
        }),
      };
    },
    sortingFunction: (a: any, b: any) => {
      const aPercent = percentageOfTasksCompletedVal(a.original);
      const bPercent = percentageOfTasksCompletedVal(b.original);

      return aPercent - bPercent;
    },
    formatFunction: (value: any, width = 200) => {
      const rounded = Math.round(value.val * 100);
      return (
        <DurationLine width={width} duration={value.duration}>
          <Progress
            value={rounded}
            label={`${rounded}%`}
            radius="xl"
            size={"1.25em"}
            miw={width}
            maw={width}
          />
        </DurationLine>
      );
    },

    type: "belongsTo",
    excludeFromForm: true,
    formOptions: {
      required: false,
    },
  },

  percentageOfProjectDuration: {
    title: "Percentage of Project Duration Passed",
    field: "percentageOfProjectDuration",
    accessorFunction: (value: any) => {
      return calculatePercentageOfDurationPassed({
        startDate: value.startDate,
        endDate: value.endDate,
      });
    },
    formatFunction: (value: any) => {
      const rounded = Math.round(value * 100);
      return (
        <Progress
          value={rounded}
          label={`${rounded}%`}
          radius="xl"
          size={"1.25em"}
          miw={200}
        />
      );
    },
    type: "belongsTo",
    excludeFromForm: true,
    formOptions: {
      required: false,
    },
  },

  integrationSystemId: {
    title: "Integration System ID",
    field: "integrationSystemId",
    excludeFromForm: true,
    excludeFromChartBuilder: true,
    formOptions: {
      required: false,
    },
  },

  tasks: {
    title: "Tasks",
    field: "tasks",
    type: "hasMany",
    accessorFunction: (row: any) => row.tasks.length,
    excludeFromForm: true,
    formOptions: {
      required: true,
    },
  },
  statements: {
    title: "Statements",
    field: "statements",
    type: "hasMany",
    accessorFunction: (row: any) => row.statements.length,
    excludeFromForm: true,
    formOptions: {
      required: true,
    },
  },
  projectRoles: {
    title: "Project Roles",
    field: "projectRoles",
    type: "hasMany",
    accessorFunction: (row: any) => row.projectRoles.length,
    excludeFromForm: true,
    formOptions: {
      required: true,
    },
  },
  risks: {
    title: "Risks",
    field: "risks",
    type: "hasMany",
    accessorFunction: (row: any) => row.risks.length,
    excludeFromForm: true,
    formOptions: {
      required: true,
    },
  },

  projectManagersNames: {
    title: "Project Managers Names",
    field: "projectManagersNames",
    formOptions: {
      required: false,
    },
    excludeFromForm: true,
    accessorFunction: (row: any) => {
      return row?.projectManagers?.items
        ?.map(
          (assignee: any) =>
            assignee.person.firstName + " " + assignee.person.lastName
        )
        .join(", ");
    },
  },

  deliveryManagers: {
    title: "Delivery Managers",
    field: "deliveryManagers",
    excludeFromChartBuilder: true,
    excludeFromDataView: true,
    formOptions: {
      required: false,
      childIdName: "personID",
      parentIdName: "projectID",
      listQuery: listDeliveryManagerProjects,
      updateQuery: updateDeliveryManagerProjects,
      createQuery: createDeliveryManagerProjects,
      deleteQuery: deleteDeliveryManagerProjects,
      listKey: "person",
      options: {
        dataSource: "people",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource
            ?.sort((a: any, b: any) => {
              return `${a.firstName} ${a.lastName}` ===
                `${b.firstName} ${b.lastName}`
                ? 0
                : `${a.firstName} ${a.lastName}` >
                  `${b.firstName} ${b.lastName}`
                ? 1
                : -1;
            })
            .map((row: any) => {
              return {
                value: row.id,
                label: `${row.firstName} ${row.lastName}`,
              };
            }) ?? [],
      },
      order: 2,
    },

    type: "manyToMany",
    // accessorFunction: (row: any) => "",
  },

  projectManagers: {
    title: "Project Managers",
    field: "projectManagers",
    excludeFromChartBuilder: true,
    excludeFromDataView: true,
    formOptions: {
      required: false,
      childIdName: "personID",
      parentIdName: "projectID",
      listQuery: listProjectManagerProjects,
      updateQuery: updateProjectManagerProjects,
      createQuery: createProjectManagerProjects,
      deleteQuery: deleteProjectManagerProjects,
      listKey: "person",
      options: {
        dataSource: "people",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource
            ?.sort((a: any, b: any) => {
              return `${a.firstName} ${a.lastName}` ===
                `${b.firstName} ${b.lastName}`
                ? 0
                : `${a.firstName} ${a.lastName}` >
                  `${b.firstName} ${b.lastName}`
                ? 1
                : -1;
            })
            .map((row: any) => {
              return {
                value: row.id,
                label: `${row.firstName} ${row.lastName}`,
              };
            }) ?? [],
      },
      order: 2,
    },

    type: "manyToMany",
    // accessorFunction: (row: any) => "",
  },

  period: {
    title: "Period",
    field: "period",
    formOptions: { required: false },
  },

  ...common, // Assuming "common" is a shared schema between different types
};

export default projectsSchema;
