import { ProjectRole } from "../../API";
import { formatCurrency } from "../../components/common/functions/formatFunctions";
import { ISchema } from "../SchemaTypes";
import common from "./commonSchema";

const timeSheetSchema: ISchema = {
  person: {
    title: "Person",
    field: "person",
    accessorFunction: (value: any) =>
      `${value.person?.firstName} ${value.person?.lastName}`,
    formatFunction: (value: any) => value,
    formOptions: { required: false },
    excludeFromForm: true,
    type: "belongsTo",
  },

  personTimesheetsId: {
    title: "Person",
    field: "personTimesheetsId",
    formOptions: {
      required: true,
      options: {
        dataSource: "people",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: `${row.firstName} ${row.lastName}` };
          }),
      },
      order: 1,
    },
    type: "select",
  },

  projectRole: {
    title: "Project Role",
    field: "projectRole",
    accessorFunction: (value: any) => value.projectRole?.description,
    formOptions: { required: false },
    excludeFromForm: true,
    type: "belongsTo",
  },

  projectRoleTimesheetsId: {
    title: "Project Role",
    field: "projectRoleTimesheetsId",
    essentialKey: true,
    formOptions: {
      required: true,
      options: {
        dataSource: "projectRoles",
        addBlank: true,
        accessorFunction: (dataSource: ProjectRole[]) =>
          dataSource.map((row) => {
            return {
              value: row.id,
              label: `${row?.project?.name ?? ""} - ${row.description} - ${
                row.unitRate
              }`,
            };
          }),
      },
      order: 1,
    },
    type: "select",
  },

  startDate: {
    title: "Start Date",
    field: "startDate",
    type: "date",
    formOptions: {
      required: false,
    },
  },
  endDate: {
    title: "End Date",
    field: "endDate",
    type: "date",
    formOptions: {
      required: false,
    },
  },

  unit: {
    title: "Unit",
    field: "unit",
    type: "select",
    formOptions: {
      required: false,
      options: { list: ["Hours", "Days", "Project Outcome"], addBlank: true },
    },
  },

  unitAmount: {
    title: "Unit Amount",
    field: "unitAmount",
    type: "number",
    formOptions: { required: false },
  },

  unitRate: {
    title: "Unit Rate",
    field: "unitRate",
    type: "currency",
    formatFunction: (value: any) => formatCurrency(value, "GBP", 2),
    formOptions: { required: false },
  },

  unitCost: {
    title: "Unit Cost",
    field: "unitCost",
    type: "currency",
    formatFunction: (value: any) => formatCurrency(value, "GBP", 2),
    formOptions: { required: false },
  },

  timesheetRef: {
    title: "Timesheet Reference",
    field: "timesheetRef",
    formOptions: { required: false },
  },

  ...common,
};

export default timeSheetSchema;
