import { useState, useEffect, useMemo } from "react";
// import { Gantt, ViewMode } from "gantt-task-react";
import { Gantt, ViewMode } from "../../common/graphs/GanttChart";
import "gantt-task-react/dist/index.css";
// import "./Gantt.css";
import {
  //   Stack,
  // Center,
  SegmentedControl,
  Group,
  //   Switch,
  //   MultiSelect,
  Paper,
  useMantineTheme,
  Switch,
  Text,
  Stack,
  LoadingOverlay,
} from "@mantine/core";
// import AppContext from "../../../context/AppContext";
import WidgetFrame from "../../common/WidgetFrame";
import { getDataSchema } from "../../common/functions/getDataSchema";
import dayjs from "dayjs";
import { formatCurrency } from "../../common/functions/formatFunctions";
import { createFilterFunction } from "../../common/functions/createFilterFunction";
import { ResponsiveContainer } from "recharts";
import { IconCalendar } from "@tabler/icons-react";
import useLoadingStatus from "../../../hooks/useLoadingStatus";

const TooltipContent = (props: any) => {
  const {
    task: { original },
  } = props;

  if (original.statements) {
    const schema = getDataSchema("projects");
    return (
      <Paper shadow="xs" p="xs" sx={{ maxWidth: 400 }}>
        <Stack>
          <Group position="apart">
            <Text fw={700} size={"md"}>
              {original.name}
            </Text>
            <Text size={"xs"} color="dimmed">
              {dayjs(original.startDate).format("DD/MM/YYYY")} -{" "}
              {dayjs(original.endDate).format("DD/MM/YYYY")}
            </Text>
          </Group>
          <Stack spacing={"xs"}>
            <Text fw={700} size={"xs"}>
              Finance Summary ({formatCurrency(original.revenueSold, "GBP", 0)})
            </Text>
            {schema.financeSummary.formatFunction(
              schema.financeSummary.accessorFunction(original),
              350
            )}
          </Stack>
          <Stack spacing={"xs"}>
            <Text fw={700} size={"xs"}>
              % Tasks Completed
            </Text>
            {schema.percentageOfTasksCompleted.formatFunction(
              schema.percentageOfTasksCompleted.accessorFunction(original),
              350
            )}
          </Stack>
        </Stack>
      </Paper>
    );
  } else {
    return (
      <Paper shadow="xs" p="md" sx={{ maxWidth: 500 }}>
        <Stack>
          <Group position="apart">
            <Text fw={700} size={"xl"}>
              {original.name}
            </Text>
            <Text size={"sm"} color="dimmed">
              {dayjs(original.startDate).format("DD/MM/YYYY")} -{" "}
              {dayjs(original.endDate).format("DD/MM/YYYY")}
            </Text>
          </Group>
        </Stack>
      </Paper>
    );
  }
};

export default function ProjectsGantt(props: any) {
  const {
    dashboardFilterObjectList,
    updateDashboardFilter,
    globalFilteredData,
    startDate,
    endDate,
  } = props;
  const [viewMode, setViewMode] = useState(ViewMode.Month);
  const [showTasks, setShowTasks] = useState<boolean>(false);
  const [showFinishedProjects, setShowFinishedProjects] =
    useState<boolean>(false);
  const [showMilestones, setShowMilestones] = useState<boolean>(true);
  const [showTaskList, setShowTaskList] = useState<boolean>(true);
  const theme = useMantineTheme();

  const loading = useLoadingStatus(["projects"]);
  // const { dataObject, currentProgrammes } = useContext(AppContext);

  // const { projects = [] } = dataObject;

  const localFilterFunction = useMemo(
    () =>
      createFilterFunction(
        [dashboardFilterObjectList],
        "startDate",
        startDate,
        endDate
      ),
    [dashboardFilterObjectList, endDate, startDate]
  );

  const filteredProjects = useMemo(() => {
    return globalFilteredData
      .filter(
        (project: any) => project.period === "Live" //&&
        // currentProgrammes.includes(project.programmeProjectsId)
      )
      .filter(localFilterFunction)
      .map((project: any) => {
        project.start = new Date(project.startDate);
        project.end = new Date(project.endDate);
        return project;
      });
  }, [globalFilteredData, localFilterFunction]);

  const ganttData = useMemo(() => {
    const todayTime = new Date().getTime();
    return (
      filteredProjects
        // .slice(0, 100)
        .sort((a: any, b: any) => a.start.getTime() - b.start.getTime())
        .reduce((array: any, project: any, index: number) => {
          if (
            project.startDate &&
            project.endDate &&
            (showFinishedProjects || project.end.getTime() > todayTime)
          ) {
            const start = project.start;
            const end = project.end;
            if (start.getTime() <= end.getTime()) {
              array.push({
                start,
                end,
                name: project.name,
                id: project.id,
                type: "project",
                progress: 100,
                isDisabled: false,
                styles: {
                  progressColor: theme.colors[theme.primaryColor][7], //"#6fbe2e",
                  progressSelectedColor: theme.colors[theme.primaryColor][7], //"#6fbe2e",
                },
                hideChildren: !showTasks,
                original: project,
                projectId: project.id,
                // dependencies: filteredProjects[index + 1]
                //   ? [filteredProjects[index + 1].id]
                //   : [],
                //   statement,
                //   numberOfDeliverables,
                //   completedDeliverables,
              });

              if (project?.tasks?.items) {
                project?.tasks?.items
                  .filter((task: any) => {
                    return (
                      (task.startDate &&
                        task.endDate &&
                        task.type === "Task" &&
                        showTasks) ||
                      (task.startDate &&
                        task.type === "Milestone" &&
                        showMilestones)
                    );
                  })
                  .map((task: any) => {
                    task.start = new Date(task.startDate);
                    task.end = new Date(task.endDate);
                    return task;
                  })
                  .sort((a: any, b: any) => {
                    return a.start.getTime() - b.start.getTime();
                  })
                  .forEach((task: any, index: number) => {
                    const start = task.start;
                    const end = task.end;
                    if (
                      start.getTime() <= end.getTime() ||
                      task.type === "Milestone"
                    ) {
                      array.push({
                        start,
                        end,
                        name: task.name,
                        id: task.id,
                        type: task.type === "Milestone" ? "milestone" : "task",
                        progress: task.complete ? 100 : 0,
                        isDisabled: false,
                        original: task,
                        styles: {
                          progressColor: theme.colors[theme.primaryColor][5], //"#6fbe2e",
                          progressSelectedColor:
                            theme.colors[theme.primaryColor][5], //"#6fbe2e",
                          backgroundColor:
                            theme.colors[theme.primaryColor][
                              task.complete ? 5 : 2
                            ],
                          backgroundSelectedColor:
                            theme.colors[theme.primaryColor][
                              task.complete ? 5 : 2
                            ],
                        },
                        projectId: project.id,
                      });
                    }
                  });
              }
            }
          }

          return array;
        }, [])
    );
  }, [
    filteredProjects,
    showTasks,
    showMilestones,
    showFinishedProjects,
    theme.colors,
    theme.primaryColor,
  ]);

  useEffect(() => {
    const months = document.querySelectorAll("._9w8d5");
    months.forEach((month) => {
      if (viewMode === "Month") {
        month.textContent = month.textContent
          ? month.textContent.slice(0, 3)
          : "";
      }
    });
  }, [viewMode]);

  const columnWidth = useMemo(() => {
    switch (viewMode) {
      case "Year":
        return 300;
      case "Month":
        return 200;
      default:
        return 100;
    }
  }, [viewMode]);

  return (
    <WidgetFrame
      title={"Projects Gantt"}
      icon={IconCalendar}
      controls={
        <Group position="apart" spacing={"xl"}>
          <Switch
            label="Show task list"
            checked={showTaskList}
            onChange={(e) => setShowTaskList(e.target.checked)}
          />
          <Switch
            label="Show finished projects"
            checked={showFinishedProjects}
            onChange={(e) => setShowFinishedProjects(e.target.checked)}
          />
          <Switch
            label="Show tasks"
            checked={showTasks}
            onChange={(e) => setShowTasks(e.target.checked)}
          />
          <Switch
            label="Show milestones"
            checked={showMilestones}
            onChange={(e) => setShowMilestones(e.target.checked)}
          />
          <SegmentedControl
            value={viewMode}
            // @ts-ignore
            onChange={(value) => setViewMode(ViewMode[value])}
            data={[
              { label: "Year", value: "Year" },
              { label: "Month", value: "Month" },
              { label: "Week", value: "Week" },
              // { label: "Day", value: "Day" },
            ]}
          />
        </Group>
      }
    >
      <LoadingOverlay visible={loading} />
      <ResponsiveContainer
        width="100%"
        height="99%"
        // style={{ overflow: "scroll" }}
        style={{ overflow: "scroll", position: "relative" }}
      >
        {ganttData.length > 0 ? (
          <Gantt
            tasks={ganttData}
            fontFamily="-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji"
            viewMode={viewMode}
            // listCellWidth={""}
            listCellWidth={showTaskList ? "100px" : ""}
            barCornerRadius={16}
            columnWidth={columnWidth}
            // barProgressColor={theme.primaryColor}
            // barBackgroundColor={theme.primaryColor}
            arrowColor={theme.colors[theme.primaryColor][6]}
            todayColor={
              theme.colorScheme === "dark"
                ? `${theme.colors.gray[8]}80`
                : `${theme.colors.gray[3]}80`
            }
            // viewDate={new Date()}
            TooltipContent={TooltipContent}
          />
        ) : (
          <></>
        )}
      </ResponsiveContainer>
    </WidgetFrame>
  );
}
