import { FinanceVisual } from "../common/graphs/FinanceVisual";
import UnitsUsedVisual from "../common/graphs/UnitsUsedVisual/UnitsUsedVisual";
// import PeopleBox from "./overview/PeopleBox";
// import SingleMetricContainer from "../common/SingleMetricContainer";
// import { CurrencyDollar, Calendar } from "tabler-icons-react";
// import { Center } from "@mantine/core";
// import DummyGantt from "./overview/DummyGantt";
import ProjectsGantt from "./widgets/ProjectsGantt";
import AccountSummary from "../common/graphs/NewAccountSummary/AccountSummary";
import ProgrammeSummary from "../common/graphs/NewProgrammeSummary/ProgrammeSummary";
import OldAccountSummary from "../common/graphs/AccountSummary/AccountSummary";
import OldProgrammeSummary from "../common/graphs/ProgrammeSummary/ProgrammeSummary";
import VerticalSummary from "../common/graphs/VerticalSummary/VerticalSummary";
import { RevenueForecast } from "../common/graphs/RevenueForecast";
import { ProjectPL } from "../common/graphs/ProjectPL";
import { ProjectRoleSummary } from "../common/graphs/ProjectRoleSummary";
// import ProjectChartOne from "../common/graphs/temp/ProjectChartOne";
// import ProjectChartTwo from "../common/graphs/temp/ProjectChartTwo";
// import ProjectChartThree from "../common/graphs/temp/ProjectChartThree";
// import ProjectChartFour from "../common/graphs/temp/ProjectChartFour";
// import RevenueChartOne from "../common/graphs/temp/RevenueChartOne";
// import RevenueChartFour from "../common/graphs/temp/RevenueChartFour";
// import RevenueChartThree from "../common/graphs/temp/RevenueChartThree";
// import RevenueChartTwo from "../common/graphs/temp/RevenueChartTwo";
import TCPFinanceVisual from "../common/graphs/temp/TSPFinanceVisual";
import { NewFinanceVisual } from "../common/graphs/NewFinanceVisual";
import DeliveryTable from "../common/graphs/temp/DeliveryTable";
import RemainingPayment from "../common/graphs/temp/RemainingPayment";
import ProjectsRemainingPayment from "../common/graphs/temp/ProjectsRemainingPayment";
import AtRiskTable from "../common/graphs/temp/AtRiskTable";
import AtRiskMRR from "../common/graphs/temp/AtRiskMRR";
import WidgetFrame from "../common/WidgetFrame";
import PipelineFunnel from "../common/graphs/FunnelChart/FunnelChart";
import ParetoAnalysisPipeline from "../common/graphs/ParetoAnalysisPipeline/ParetoAnalysisPipeline";
import ResourcingTable from "../common/graphs/ResourcingTable/ResourcingTable";
import TasksSummary from "../common/graphs/TasksSummary/TasksSummary";
import { TemperamentsReport } from "../common/ClientComponents/Connect/TemperamentsReport";
import { QuestionnaireReport } from "../common/ClientComponents/Connect/QuestionnaireReport";
// import SingleMetricContainer from "../common/SingleMetricContainer";
// import { Center } from "@mantine/core";
// import { IconCurrencyPound } from "@tabler/icons-react";

export const widgetsObject = {
  tasksSummary: {
    component: TasksSummary,
    name: "Tasks Summary",
    widgetKey: "tasksSummary",
    defaults: {
      w: 6,
      h: 6,
    },
    configuration: {
      dataSourceKey: "tasks",
      period: "Live",
      comparisonPeriod: "None",
      filterObjects: [],
    },
  },
  resourcingTable: {
    component: ResourcingTable,
    name: "Resourcing Table",
    widgetKey: "resourcingTable",
    defaults: {
      w: 6,
      h: 6,
    },
    configuration: {
      dataSourceKey: "people",
      period: "Live",
      comparisonPeriod: "None",
      filterObjects: [],
    },
  },

  revenueForecast: {
    component: RevenueForecast,
    name: "Revenue Forecast",
    widgetKey: "revenueForecast",
    defaults: {
      w: 12,
      h: 6,
    },
    configuration: {
      dataSourceKey: "projects",
      period: "Live",
      comparisonPeriod: "None",
      filterObjects: [],
    },
  },

  projectPL: {
    component: ProjectPL,
    name: "Project Profit & Loss",
    widgetKey: "projectPL",
    defaults: {
      w: 12,
      h: 6,
    },
    configuration: {
      dataSourceKey: "projects",
      period: "Live",
      comparisonPeriod: "None",
      filterObjects: [],
    },
  },

  projectRoleSummary: {
    component: ProjectRoleSummary,
    name: "Project Role Summary",
    widgetKey: "projectRoleSummary",
    defaults: {
      w: 12,
      h: 6,
    },
    configuration: {
      dataSourceKey: "projectRoles",
      period: "Live",
      comparisonPeriod: "None",
      filterObjects: [],
    },
  },

  projectsSection: {
    component: ProjectsGantt,
    name: "Projects Section",
    widgetKey: "projectsSection",
    defaults: {
      w: 6,
      h: 6,
    },
    configuration: {
      dataSourceKey: "projects",
      period: "Live",
      comparisonPeriod: "None",
    },
  },

  paretoAnalysisPipeline: {
    component: ParetoAnalysisPipeline,
    name: "Customer Pareto Analysis",
    widgetKey: "paretoAnalysisPipeline",
    defaults: {
      w: 6,
      h: 6,
    },
    configuration: {
      dataSourceKey: "pipelines",
      period: "Live",
      comparisonPeriod: "None",
    },
  },

  pipelineFunnel: {
    component: PipelineFunnel,
    name: "Pipeline Funnel",
    widgetKey: "pipelineFunnel",
    defaults: {
      w: 6,
      h: 6,
    },
    configuration: {
      dataSourceKey: "pipelines",
      period: "Live",
      comparisonPeriod: "None",
    },
  },

  finance: {
    component: FinanceVisual,
    name: "Finance",
    widgetKey: "finance",
    defaults: {
      w: 6,
      h: 6,
    },
    configuration: {
      dataSourceKey: "projects",
      period: "Live",
      comparisonPeriod: "None",
    },
  },

  newFinance: {
    component: NewFinanceVisual,
    name: "New Finance",
    widgetKey: "newFinance",
    defaults: {
      w: 6,
      h: 6,
    },
    configuration: {
      dataSourceKey: "projects",
      period: "Live",
      comparisonPeriod: "None",
      rows: ["associatedDealCloseDate"],
    },
  },

  unitsUsed: {
    component: UnitsUsedVisual,
    name: "Units Used",
    widgetKey: "unitsUsed",
    defaults: {
      w: 6,
      h: 6,
    },
    configuration: {
      dataSourceKey: "projects",
      period: "Live",
      comparisonPeriod: "None",
    },
  },

  newAccountSummary: {
    component: AccountSummary,
    name: "New Account Summary",
    widgetKey: "newAccountSummary",
    defaults: {
      w: 12,
      h: 12,
    },
    configuration: {
      dataSourceKey: "projects",
      period: "Live",
      comparisonPeriod: "None",
      rows: ["associatedDealCloseDate"],
    },
  },
  newProgrammeSummary: {
    component: ProgrammeSummary,
    name: "New Programme Summary",
    widgetKey: "newProgrammeSummary",
    defaults: {
      w: 12,
      h: 12,
    },
    configuration: {
      dataSourceKey: "projects",
      period: "Live",
      comparisonPeriod: "None",
      rows: ["associatedDealCloseDate"],
    },
  },

  accountSummary: {
    component: OldAccountSummary,
    name: "Account Summary",
    widgetKey: "accountSummary",
    defaults: {
      w: 12,
      h: 12,
    },
    configuration: {
      dataSourceKey: "accounts",
      period: "Live",
      comparisonPeriod: "None",
      rows: ["associatedDealCloseDate"],
    },
  },

  //Temperaments Report
  temperamentsReport: {
    component: TemperamentsReport,
    name: "Temperaments Report",
    widgetKey: "temperamentsReport",
    defaults: {
      w: 12,
      h: 12,
    },
    configuration: {
      dataSourceKey:
        window.location.hostname === "localhost"
          ? "4363bc08-b0bf-4667-913e-06f280128d84"
          : "2ffafde5-20eb-4ccc-8989-60ad61a51e04", //"4363bc08-b0bf-4667-913e-06f280128d84",
      period: "Live",
      comparisonPeriod: "None",
    },
  },

  questionnaireReport: {
    component: QuestionnaireReport,
    name: "Questionnaire Report",
    widgetKey: "questionnaireReport",
    defaults: {
      w: 12,
      h: 12,
    },
    configuration: {
      dataSourceKey:
        window.location.hostname === "localhost"
          ? "c6b0e19b-252f-4166-836f-1ff9f4d817a9"
          : "6e246f3e-2b6c-487e-97a7-8d233912c6aa",
      period: "Live",
      comparisonPeriod: "None",
    },
  },

  programmeSummary: {
    component: OldProgrammeSummary,
    name: "Programme Summary",
    widgetKey: "programmeSummary",
    defaults: {
      w: 12,
      h: 12,
    },
    configuration: {
      dataSourceKey: "programmes",
      period: "Live",
      comparisonPeriod: "None",
    },
  },

  verticalSummary: {
    component: VerticalSummary,
    name: "Channel Summary",
    widgetKey: "verticalSummary",
    defaults: {
      w: 12,
      h: 12,
    },
    configuration: {
      dataSourceKey: "projects",
      period: "Live",
      comparisonPeriod: "None",
      rows: ["associatedDealCloseDate"],
    },
  },

  tcpFinance: {
    component: (props: any) => (
      <WidgetFrame title={"Engagement Details"}>
        <TCPFinanceVisual {...props} />
      </WidgetFrame>
    ),
    name: "TCP Finance",
    widgetKey: "tcpFinance",
    defaults: {
      w: 12,
      h: 12,
    },
    configuration: {
      dataSourceKey: "pls",
      period: "Live",
      comparisonPeriod: "None",
    },
  },

  deliveryTable: {
    component: DeliveryTable,
    name: "Delivery Table",
    widgetKey: "deliveryTable",
    defaults: {
      w: 12,
      h: 12,
    },
    configuration: {
      dataSourceKey: "pls",
      period: "Live",
      comparisonPeriod: "None",
    },
  },
  atRiskTable: {
    component: AtRiskTable,
    name: "At Risk Table",
    widgetKey: "atRiskTable",
    defaults: {
      w: 12,
      h: 12,
    },
    configuration: {
      dataSourceKey: "pls",
      period: "Live",
      comparisonPeriod: "None",
    },
  },
  atRiskMRR: {
    component: AtRiskMRR,
    name: "At Risk Status",
    widgetKey: "atRiskMRR",
    defaults: {
      w: 12,
      h: 12,
    },
    configuration: {
      dataSourceKey: "mrrs",
      period: "Live",
      comparisonPeriod: "None",
    },
  },
  remainingPayment: {
    component: RemainingPayment,
    name: "Remaining Payment",
    widgetKey: "remainingPayment",
    defaults: {
      w: 12,
      h: 12,
    },
    configuration: {
      dataSourceKey: "pls",
      period: "Live",
      comparisonPeriod: "None",
    },
  },
  projectsRemainingPayment: {
    component: ProjectsRemainingPayment,
    name: "Projects Remaining Payment",
    widgetKey: "projectsRemainingPayment",
    defaults: {
      w: 12,
      h: 12,
    },
    configuration: {
      dataSourceKey: "projects",
      period: "Live",
      comparisonPeriod: "None",
    },
  },
};
